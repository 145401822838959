<template>
  <div class="container">
    <div class="row" v-if="nft.id && nft.metadata">
      <div class="col-lg-3 col-sm-12 text-justify">
        <h3 id="nft-name">
          <strong>
            {{nft.metadata.name}}
          </strong>
          <br/>
        </h3>
        <p v-if="nft.owner">
          <strong>Owner: </strong> <MetamaskAddress v-bind:address="nft.owner" short="yes"></MetamaskAddress>
        </p>
        <img v-if="nft.metadata" v-bind:src="nft.metadata.image" class="rat-image img-fluid" />
      </div>
      <div class="col-lg-9 col-sm-12">
        <template v-if="nft.owner && $store.state.marketContract">
          <MarketV3
              v-bind:ca="nftAddress"
              v-bind:id="nft.id"
              v-bind:owner="nft.owner"
              brief="no">
          </MarketV3>
          <Bid
              v-bind:ca="nftAddress"
              v-bind:id="nft.id"
              v-bind:owner="nft.owner">
          </Bid>
          <Transfer
              v-if="nft.owner.toLowerCase() == $store.state.address.toLowerCase()"
              v-bind:ca="nftAddress"
              v-bind:id="nft.id">
          </Transfer>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Bid from "@/components/Rat/Bid";
import Transfer from "@/components/Rat/Transfer";
import MarketV3 from "@/components/Rat/MarketV3";
import MetamaskAddress from "@/components/MetamaskAddress";

import erc1155 from '../../../../artifacts/contracts/RPS.sol/RPS.json';
import erc721abi from '../../../../indexers/abi/erc721.json';
import {ethers} from "ethers";

export default {
  components: {
    MetamaskAddress,
    Bid,
    Transfer,
    MarketV3,
  },
  watch: {
    '$store.state.provider': function() {
      this.loadNft();
    }
  },
  data() {
    return {
      nftAddress: null,
      nftContract: null,
      nft: {
        id: null,
        owner: null,
        metadata: null,
      },
      rat: {
        firstname: null,
        name: null,
        number: null,
        hash: null,
        image: null,
        'rat-number': null,
        'juungle-price': null,
        owner: null,
        'rat-data': null,
        traits: {},
        duplicates: null,
        lookup: null,
        generation: 1,
        smart: true,
      },
    }
  },
  methods: {
    loadNft() {
      this.nft.id = this.$route.params.id;
      this.nftAddress = this.$route.params.collection;
      this.nftContract = new ethers.Contract(this.$route.params.collection, erc721abi, this.$store.state.provider);
      this.nftContract.supportsInterface('0xd9b67a26').then(supported => {
        if(supported) {
          this.nftContract = new ethers.Contract(this.$route.params.collection, erc1155.abi, this.$store.state.provider);
          this.nftContract.uri(this.$route.params.id).then((uri) => {
            fetch(uri).then(response => {
              return response.text().then(response => {
                let jsonText = response.replace("}", '"dummy": "dummy"}');
                console.log(jsonText);
                return JSON.parse(jsonText);
              });
            }).then(metadata => {
              this.nft.metadata = metadata;
            });
            this.$store.state.marketContract.owns(this.$store.state.address, this.$route.params.collection, this.$route.params.id, 1).then(owns => {
              if(owns) {
                this.nft.owner = this.$store.state.address;
              } else {
                this.nft.owner = 'Unknown';
              }
            });
          });
        } else {
          this.nftContract.tokenURI(this.$route.params.id).then((uri) => {
            fetch(uri).then(response => {
              return response.json();
            }).then(metadata => {
              this.nft.metadata = metadata;
            });
          });
          this.nftContract.ownerOf(this.$route.params.id).then((owner) => {
            this.nft.owner = owner;
          });
        }
      });
    },
  },
  mounted() {
    if(this.$store.state.provider) {
      this.loadNft();
    }
  }
}
</script>
